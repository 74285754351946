var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-navbar',{staticClass:"mb-4",attrs:{"toggleable":"lg","type":"dark","variant":"dark"}},[_c('router-link',{style:({ cursor: 'pointer'}),attrs:{"to":{ name: 'Carousel'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-navbar-brand',{staticClass:"m-1 ml-5",attrs:{"role":"link"},on:{"click":navigate}},[_c('b-icon',{attrs:{"icon":"house","font-scale":"1.4"}})],1)]}}])}),_c('b-navbar-toggle',{staticClass:"mr-auto",attrs:{"target":"nav-collapse"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var expanded = ref.expanded;
return [(expanded)?_c('b-icon',{attrs:{"variant":"white","icon":"box-arrow-in-up"}}):_c('b-icon',{attrs:{"variant":"white","icon":"box-arrow-in-down"}})]}}])}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"mr-auto ml-5"},[_c('b-nav-item-dropdown',{attrs:{"text":_vm.$t('Navbar.prj')}},[_c('router-link',{attrs:{"to":{name: 'BackOffice'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-dropdown-item',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Back-Office")])]}}])}),_c('b-dropdown-divider'),_c('router-link',{attrs:{"to":{name: 'Clipping'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-dropdown-item',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Clippingz")])]}}])}),_c('b-dropdown-divider'),_c('router-link',{attrs:{"to":{name: 'Memoria'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-dropdown-item',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('Navbar.memoria')))])]}}])}),_c('b-dropdown-divider'),_c('router-link',{attrs:{"to":{name: 'TicketSystem'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-dropdown-item',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('TelaTS.desc')))])]}}])}),_c('b-dropdown-divider'),_c('router-link',{attrs:{"to":{name: 'TicketSystemBO'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-dropdown-item',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('TelaTS.descBo')))])]}}])})],1),_c('b-nav-item-dropdown',{attrs:{"text":_vm.$t('Navbar.curriculo')}},[_c('router-link',{attrs:{"to":{name: 'Curriculo'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-dropdown-item',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Português")])]}}])}),_c('b-dropdown-divider'),_c('router-link',{attrs:{"to":{name: 'Resume'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-dropdown-item',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Inglês")])]}}])})],1)],1),_c('div',[_c('b-button-group',{attrs:{"size":"sm"}},[_c('b-button',{attrs:{"variant":"outline-dark"},on:{"click":function($event){return _vm.changeLocale(_vm.languages[0].language)}}},[_c('flag',{attrs:{"iso":_vm.languages[0].flag}}),_vm._v(" "),_c('br'),_vm._v(_vm._s(_vm.languages[0].title)+" ")],1),_c('b-button',{attrs:{"variant":"outline-dark"},on:{"click":function($event){return _vm.changeLocale(_vm.languages[1].language)}}},[_c('flag',{attrs:{"iso":_vm.languages[1].flag}}),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(_vm.languages[1].title)+" ")],1),_c('b-button',{attrs:{"variant":"outline-dark"},on:{"click":function($event){return _vm.changeLocale(_vm.languages[2].language)}}},[_c('flag',{attrs:{"iso":_vm.languages[2].flag}}),_vm._v(" "),_c('br'),_vm._v(_vm._s(_vm.languages[2].title)+" ")],1)],1)],1)],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }